import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="conteudo_geral">
          <noscript
            aria-hidden="true"
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQDRJ93" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n// **** Scroll na pag 'como-funciona' : ****\njQuery(window).on('load', function() {\nvar numberSlides = 3;\t\t//**** Alterar este nr sempre que for necessario incluir novas div com conteudo nesta pagina especifica; corresponde ao nr total de divs\nvar distance = 500;\nvar timing = 750;\njQuery('#div_pageNr').html('1');\njQuery('#div_setacima').hide();\njQuery('#div_setacima').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) - 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >= numberSlides ){\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\njQuery('#div_setabaixo').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) + 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >=2){\t\t\t//**** Alterar este nr sempre que for necessario ajustar o nr de divs com conteudo a mostrar nesta pagina especifica\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\n});\n"
            }}
          />
          <div id="head_geral" className="container">
            <div className="container">
              <header id="head" role="banner">
                <div className="five columns alpha">
                  <div className="bisnaga" />
                </div>
                <div className="menu_linha_cima" />
                <div className="menu_linha_abaixo" />
                <div
                  className="eleven columns omega"
                  style={{
                    float: "right"
                  }}
                >
                  <div className="cabecalho_esq">
                    <div className="div_logo">
                      <a href="/" title="Início">
                        <img
                          className="img_logo"
                          src="/sites/default/files/color/responsive/logo.png"
                          alt="Início"
                        />
                      </a>{" "}
                    </div>
                  </div>
                  <nav id="navigation" role="navigation">
                    <div id="main-menu">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/o-que-e-biafine">O que é biafine®</a>
                        </li>
                        <li className="leaf">
                          <a href="/quando-e-como-aplicar">
                            Quando e como <br/>aplicar
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/como-funciona">Como funciona</a>
                        </li>
                        <li className="leaf">
                          <a href="/balsamo-multi-reparador-apaziguante"><span className="blue-text">Cica</span> biafine® <br/><span className="align-leaf">Cosmético</span></a>
                        </li>
                        <li className="last leaf">
                          <a href="/contactos">Contactos</a>
                        </li>
                        
                      </ul>{" "}
                    </div>
                  </nav>
                </div>
              </header>
            </div>
          </div>
          <div className="container" id="content-contain">
            <div className="menu_shadow_homepage" />
            <div className="container_hp">
              <div
                id="flexslider_views_slideshow_main_banner_homepage-block"
                className="flexslider_views_slideshow_main views_slideshow_main flexslider_views_slideshow-processed"
              >
                <div className="flex-nav-container">
                  <div
                    style={{
                      position: "absolute",
                      "margin-top": "340px",
                      "margin-left": "10px",
                      "z-index": "3"
                    }}
                  >
                    <ul className="flex-direction-nav">
                      <li>
                        <a className="prev" href="#">
                          Previous
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      "margin-top": "340px",
                      "margin-left": "1200px",
                      "z-index": "3"
                    }}
                  >
                    <ul className="flex-direction-nav">
                      <li>
                        <a className="next" href="#">
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-front-welcome">
                <div
                  id="block-views-banner-homepage-block"
                  className="block block-views"
                >
                  <div className="content">
                    <div className="view view-banner-homepage view-id-banner_homepage view-display-id-block view-dom-id-d14a7b1a0aab24b7b1f63e15e637d22f">
                      <div className="view-content">
                        <div className="skin-default">
                          <div
                            id="flexslider_views_slideshow_main_banner_homepage-block"
                            className="flexslider_views_slideshow_main views_slideshow_main"
                          >
                            <div className="flex-nav-container">
                              <div className="flexslider">
                                <ul
                                  id="flexslider_views_slideshow_banner_homepage-block"
                                  className="flexslider-views-slideshow-main-frame slides"
                                >
                                  <li className="flexslider-views-slideshow-main-frame-row flexslider_views_slideshow_slide views-row-1 views-row-odd">
                                    <div className="views-field views-field-field-imagem-slider">
                                      {" "}
                                      <div className="field-content">
                                        <img
                                          typeof="foaf:Image"
                                          src="/sites/default/files/banner_1-slide-image-v4.png"
                                          width="1424"
                                          height="570"
                                          alt
                                        />
                                      </div>
                                    </div>
                                    <div className="views-field views-field-field-url">
                                      {" "}
                                      <div className="field-content">
                                        <a
                                          href="/o-que-e-biafine"
                                          title="SAIBA MAIS >"
                                        >
                                          SAIBA MAIS &gt;
                                        </a>
                                      </div>
                                    </div>
                                    <div className="views-field views-field-title">
                                      {" "}
                                      <span className="field-content">
                                        Queimaduras* na cozinha?
                                      </span>
                                    </div>
                                    <p className="flex-caption">
                                      Não permita que as pequenas queimaduras
                                      estraguem a festa!
                                    </p>
                                    <div className="views-field views-field-field-subtitulo">
                                      {" "}
                                      <div className="field-content">
                                        *Queimaduras de 1º grau
                                      </div>
                                    </div>
                                    <div className="views-field views-field-field-subtexto">
                                      {" "}
                                      <div className="field-content">
                                        BIAFINE® trate bem da sua pele.
                                      </div>
                                    </div>
                                  </li>
                                  <li className="flexslider-views-slideshow-main-frame-row flexslider_views_slideshow_slide views-row-2 views_slideshow_cycle_hidden views-row-even">
                                    <div className="views-field views-field-field-imagem-slider">
                                      {" "}
                                      <div className="field-content">
                                        <img
                                          typeof="foaf:Image"
                                          src="/sites/default/files/banner_2-slide-image-2-1424x570-v5_0.png"
                                          width="1424"
                                          height="570"
                                          alt
                                        />
                                      </div>
                                    </div>
                                    <div className="views-field views-field-field-url">
                                      {" "}
                                      <div className="field-content">
                                        <a
                                          href="/o-que-e-biafine"
                                          title="SAIBA MAIS >"
                                        >
                                          SAIBA MAIS &gt;
                                        </a>
                                      </div>
                                    </div>
                                    <div className="views-field views-field-title">
                                      {" "}
                                      <span className="field-content">
                                        Feridas* na Bricolage?
                                      </span>
                                    </div>
                                    <p className="flex-caption">
                                      Não deixe a meio aquela estante que
                                      finalmente começou a montar!
                                    </p>
                                    <div className="views-field views-field-field-subtitulo">
                                      {" "}
                                      <div className="field-content">
                                        *Feridas cutâneas superficiais não
                                        infetadas
                                      </div>
                                    </div>
                                    <div className="views-field views-field-field-subtexto">
                                      {" "}
                                      <div className="field-content">
                                        BIAFINE® trate bem da sua pele.
                                      </div>
                                    </div>
                                  </li>
                                  <li className="flexslider-views-slideshow-main-frame-row flexslider_views_slideshow_slide views-row-3 views_slideshow_cycle_hidden views-row-odd">
                                    <div className="views-field views-field-field-imagem-slider">
                                      {" "}
                                      <div className="field-content">
                                        <img
                                          typeof="foaf:Image"
                                          src="/sites/default/files/banner_3-slide-image-3-1424x570-v5.png"
                                          width="1424"
                                          height="570"
                                          alt
                                        />
                                      </div>
                                    </div>
                                    <div className="views-field views-field-field-url">
                                      {" "}
                                      <div className="field-content">
                                        <a
                                          href="/o-que-e-biafine"
                                          title="SAIBA MAIS >"
                                        >
                                          SAIBA MAIS &gt;
                                        </a>
                                      </div>
                                    </div>
                                    <div className="views-field views-field-title">
                                      {" "}
                                      <span className="field-content">
                                        Queimaduras* solares?
                                      </span>
                                    </div>
                                    <p className="flex-caption">
                                      Alivie o desconforto e hidrate a sua pele
                                      após a exposição ao sol.
                                    </p>
                                    <div className="views-field views-field-field-subtitulo">
                                      {" "}
                                      <div className="field-content">
                                        *Eritema solar
                                      </div>
                                    </div>
                                    <div className="views-field views-field-field-subtexto">
                                      {" "}
                                      <div className="field-content">
                                        BIAFINE® trate bem da sua pele.
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html:
                  "\njQuery(window).on('load', function() {\njQuery('.BlocoHP-textoVerdeClaro').each(function() {\nvar url = jQuery(this).attr('href');\n});\n});\n"
              }}
            />
            <div id="block_hp" className="sidebar five columns">
              <div className="region region-block-hp">
                <div
                  id="block-views-bloco-homepage2-block"
                  className="block block-views"
                >
                  <div className="content">
                    <div className="view view-bloco-homepage2 view-id-bloco_homepage2 view-display-id-block view-dom-id-d8087a2b6addb7b48060583a64baedfd">
                      <div className="view-content">
                        <a href="/o-que-e-biafine">
                          <div className="views-row views-row-1 views-row-odd views-row-first blocos-hp2">
                            <div className="views-field views-field-field-imagem-bloco">
                              {" "}
                              <div className="field-content">
                                <img
                                  typeof="foaf:Image"
                                  src="/sites/default/files/homepage_bloco_img1_0.png"
                                  width="222"
                                  height="94"
                                  alt
                                />
                              </div>
                            </div>
                            <div className="views-field views-field-field-url-bloco-hp">
                              {" "}
                              <div className="field-content">
                                <a
                                  href="/o-que-e-biafine"
                                  className="BlocoHP-textoVerdeClaro"
                                >
                                  O que é Biafine
                              </a>
                              </div>
                            </div>
                            <div>
                              {" "}
                              <div className="BlocoHP-textoCinza">
                                Saiba tudo sobre o Biafine
                            </div>
                            </div>
                          </div>

                        </a>
                        <a href="/quando-e-como-aplicar">
                          <div className="views-row views-row-2 views-row-even blocos-hp2">
                            <div className="views-field views-field-field-imagem-bloco">
                              {" "}
                              <div className="field-content">
                                <img
                                  typeof="foaf:Image"
                                  src="/sites/default/files/homepage_bloco_img2_0.png"
                                  width="222"
                                  height="94"
                                  alt
                                />
                              </div>
                            </div>
                            <div className="views-field views-field-field-url-bloco-hp">
                              {" "}
                              <div className="field-content">
                                <a
                                  href="/quando-e-como-aplicar"
                                  className="BlocoHP-textoVerdeClaro"
                                >
                                  Quando e como aplicar
                              </a>
                              </div>
                            </div>
                            <div>
                              {" "}
                              <div className="BlocoHP-textoCinza">
                                Em que situações utilizar
                            </div>
                            </div>
                          </div>
                        </a>
                        <a href="/como-funciona">
                          <div className="views-row views-row-3 views-row-odd views-row-last blocos-hp2">
                            <div className="views-field views-field-field-imagem-bloco">
                              {" "}
                              <div className="field-content">
                                <img
                                  typeof="foaf:Image"
                                  src="/sites/default/files/homepage_bloco_img3_0.png"
                                  width="222"
                                  height="94"
                                  alt
                                />
                              </div>
                            </div>
                            <div className="views-field views-field-field-url-bloco-hp">
                              {" "}
                              <div className="field-content">
                                <a
                                  href="/como-funciona"
                                  className="BlocoHP-textoVerdeClaro"
                                >
                                  Como funciona
                              </a>
                              </div>
                            </div>
                            <div>
                              {" "}
                              <div className="BlocoHP-textoCinza">
                                Conheça a fórmula de Biafine
                            </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html:
                  '\n//$(window).load(function() {\n//var str = $("body").html().replace(/\\*/g, "<span class="smaller" style="font-size:14px; vertical-align:text-top;">* </span>");\n//var str = $("body").html().replace(/*/g, "<span class="smaller" style="font-size:14px; vertical-align:text-top;">* </span>");\n//$("body").html(str);\n//});\n'
              }}
            />
            <div className="clear" />
          </div>
          <div id="copyright_geral" className="container">
            <div id="copyright" className="container">
              <div className="background_img_homepage" />
              <div className="rodape_esq">
                <div className="region region-footer">
                  <div id="block-block-1" className="block block-block">
                    <div className="content">
                      <p>
                        www.biafine.pt © Johnson &amp; Johnson Limitada.
                        PT/BIA/16-1068
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodapeDir rodape_dir">
                <div className="region region-footer-menu">
                  <div
                    id="block-menu-menu-menu-rodape"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/politica-de-privacidade" title>
                            POLÍTICA DE PRIVACIDADE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/cookie-policy" title>
                            POLÍTICA DE COOKIES
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/aviso-legal" title>
                            AVISO LEGAL
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/mapa-do-site" title>
                            MAPA DO SITE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/contactos" title>
                            CONTACTOS
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodape_centro">
                <div className="region region-footer-center">
                  <div id="block-block-4" className="block block-block">
                    <div className="content">
                      <div>
                        Este site é publicado pela Johnson &amp; Johnson
                        Limitada única responsável pelo seu
                        conteúdo.&nbsp;Destina-se a utilizadores em Portugal.
                      </div>
                      <div>
                        V03 BIAFINE® 6,7 mg/g, emulsão cutânea. Trolamina.
                        Medicamento não sujeito a receita médica. Indicado em
                        queimaduras do 1º grau, eritema solar e feridas cutâneas
                        superficiais não infetadas. BIAFINE® não confere
                        proteção solar e deve ser afastado dos olhos. Não deve
                        ser utilizado como um produto cosmético em pele
                        saudável. Contém parabenos, propilenoglicol e sorbato de
                        potássio que podem originar reações cutâneas ou
                        alérgicas. Contraindicado em doentes com
                        hipersensibilidade à substância ativa ou a qualquer um
                        dos excipientes e em feridas com hemorragia e infetadas.
                        Leia cuidadosamente as informações constantes da
                        embalagem e do folheto informativo. Em caso de dúvida ou
                        de persistência dos sintomas consulte o seu médico ou
                        farmacêutico. Johnson &amp; Johnson Lda. Lagoas Park,
                        Edifício 9, 2740-262 Porto Salvo. NUIPC 500 153 370.
                        <br />
                        &nbsp;
                      </div>
                      <div>&nbsp;</div>
                      <div className="last leaf">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Configuração de cookies
                          </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default Page;
